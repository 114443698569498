import React from "react";

import { TableColumnTitle } from "./table-column-title";
import { TableColumn } from "../../../../../../models";

interface TableColumnsRowProps {
  columns: Array<TableColumn>;
}

export const TableColumnsRow: React.FC<TableColumnsRowProps> = ({
  columns,
}) => {
  return (
    <div className="main-table-head">
      <div className="main-table-row">
        {columns.map(({ title, dataKey, hideColumn }) =>
          !hideColumn ? <TableColumnTitle key={dataKey} value={title} /> : null,
        )}
        <div className="table-inner-item table-print-item" />
      </div>
    </div>
  );
};
