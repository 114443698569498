import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Modal } from "../../shared";
import { useStore, useTranslation } from "../../../../hooks";
import { REGEX_NUMERIC, T } from "../../../../constants";
import SuccessPopUp from "./success-popup";
import { formatNumber } from "../../../../helpers";
import { useAmountValidation } from "../../../../hooks/use-amount-validations";

interface EditBalanceLimitModalProps {}

export const EditBalanceLimitModal: React.FC<EditBalanceLimitModalProps> =
  observer(() => {
    const {
      userStore: {
        wallet: {
          data: { balance, currencyCode },
        },
      },
      modalStore: { setIsOpenBalanceAndLimitModal },
      partnerStore: { isPinRequiredForBalanceChange },
      managerStore: {
        setTransferData,
        setTakeBalance,
        errorMessageForManagerBalance,
        setErrorMessageForManagerBalance,
        isShowSuccessesPart,
        setIsShowSuccessesPart,
      },
    } = useStore();

    const { userStore } = useStore();

    const [isDepositTab, setIsDepositTab] = useState(true);
    const [balanceChange, setBalanceChange] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [showPin, setShowPin] = useState(false);

    const t = useTranslation();

    useEffect(() => {
      setErrorMessageForManagerBalance("");
      setPinCode("");
    }, [isDepositTab]);

    function handleClose() {
      setIsShowSuccessesPart(false);
      setIsOpenBalanceAndLimitModal(false);
      setErrorMessageForManagerBalance(null);
    }

    const handleGetBalance = useCallback(() => {
      if (isDepositTab) {
        setTakeBalance(+balanceChange, pinCode).then((r) =>
          userStore.getWallet(),
        );
      } else {
        setTransferData(+balanceChange, pinCode).then((r) =>
          userStore.getWallet(),
        );
      }
    }, [isDepositTab, balanceChange, balance, pinCode]);

    const handleChangeInput = useCallback((value: string) => {
      setErrorMessageForManagerBalance("");
      setBalanceChange(value);
    }, []);

    const handlePinCode = (value: string) => {
      if (REGEX_NUMERIC.test(value) && value.length <= 5) {
        setPinCode(value);
      }
    };

    const { onChange } = useAmountValidation(handleChangeInput);

    return (
      <Modal onClose={handleClose} title={T.BALANCE_OPERATIONS}>
        {!isShowSuccessesPart ? (
          <div className="balanceOperationsPopupContent">
            <div className="balanceOperationsCnt">
              <div className="popupTabsHolder">
                <div
                  className={`popupTab truncate ${isDepositTab ? "active" : ""}`}
                  onClick={() => {
                    setIsDepositTab(true);
                    setBalanceChange("");
                  }}
                >
                  {t(T.DEPOSIT)}
                </div>
                <div
                  className={`popupTab truncate ${!isDepositTab ? "active" : ""} `}
                  onClick={() => {
                    setIsDepositTab(false);
                    setBalanceChange("");
                  }}
                >
                  {t(T.WITHDRAW)}
                </div>
              </div>
              <div className="popupBalanceInfo">
                <div className="popupBalanceInfoRow">
                  <span className="popupBalanceInfoText">
                    {t(T.CURRENT)} {t(T.BALANCE)}:
                  </span>
                  <span className="popupBalanceInfoText">
                    {formatNumber(balance)} {currencyCode}
                  </span>
                </div>
              </div>
              <div className="popupInputsContainer">
                {/*todo add class "error"*/}
                <div className="popupInputRow">
                  <div className="popupInputHolder">
                    <input
                      type="text"
                      className="popupInput"
                      placeholder="Amount"
                      value={balanceChange}
                      onChange={(e) => onChange(e.target.value)}
                    />
                  </div>
                </div>
                {/*todo add class "error"*/}
                {isPinRequiredForBalanceChange ? (
                  <div className="popupInputRow mb-0">
                    <div className="popupInputHolder">
                      {pinCode ? (
                        <i
                          className={`popupInputIcon icon-eye${!showPin ? "-off" : ""}`}
                          onClick={() => setShowPin(!showPin)}
                        />
                      ) : null}
                      <input
                        type={showPin ? "text" : "password"}
                        className="popupInput"
                        placeholder={t(T.PIN)}
                        onChange={(e) => handlePinCode(e.target.value)}
                        value={pinCode}
                      />
                    </div>
                    {/*<p className="inputMessage error">Error text goes here</p>*/}
                  </div>
                ) : null}
                {errorMessageForManagerBalance ? (
                  <p className="inputMessage error">
                    {t(errorMessageForManagerBalance)}
                  </p>
                ) : null}
              </div>
              <div className="btn-padding-holder">
                <button
                  className={`common-button pp-button active full ${(isPinRequiredForBalanceChange && balanceChange && pinCode) || (!isPinRequiredForBalanceChange && balanceChange) ? "" : "disabled"}`}
                  onClick={handleGetBalance}
                >
                  {isDepositTab ? t(T.DEPOSIT) : t(T.WITHDRAW)}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <SuccessPopUp />
        )}
      </Modal>
    );
  });
