import { RoutePaths } from "./route-paths";
import { T } from "./translation-keys";
import { IconTypes } from "../enums";

export const NAVIGATION_ITEMS = [
  // {
  //   label: T.PROVIDERS,
  //   path: RoutePaths.Game,
  //   icon: IconTypes.Bento
  // },
  {
    label: T.GAMES_NAV,
    path: RoutePaths.Game,
    icon: IconTypes.Games,
  },
  {
    label: T.PLACED_BETS,
    path: RoutePaths.Bets,
    icon: IconTypes.PlacedBets,
  },
  // {
  //   label: T.TRANSACTIONS,
  //   path: RoutePaths.Transactions,
  //   icon: IconTypes.PlacedBets
  // }
];
