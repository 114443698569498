import { useEffect, useMemo } from "react";
import JsBarcode from "jsbarcode";

import PascalGamingImg from "../../../../../assets/images/pascal-gaming.png";
import SecondGamingImg from "../../../../../assets/images/big-sec-logo-light.png";
import { DRAW_SECOND_LOGO } from "../../../../../constants/host-name";
import { useTranslation } from "../../../../../hooks";
import { T } from "../../../../../constants";
import { log } from "util";
import { env } from "../../../../../environment";

interface TicketProps {
  barcode: number;
  showLogo: boolean;
  logo: string;
  onImageLoad: () => void;
  onBarcodeLoad: () => void;
  children: React.ReactNode;
}

export const Ticket: React.FC<TicketProps> = ({
  barcode,
  children,
  onImageLoad,
  onBarcodeLoad,
  showLogo,
  logo,
}) => {
  const t = useTranslation();

  useEffect(() => {
    if (+barcode) {
      generateBarCode();
    } else {
      onBarcodeLoad();
    }
  }, [barcode]);

  const generateBarCode = () => {
    JsBarcode("#barcode", `${barcode}`, {
      width: 3,
      height: 72,
      displayValue: false,
    });
  };

  const drawLogo = useMemo(() => {
    let image = PascalGamingImg;
    if (DRAW_SECOND_LOGO) {
      image = SecondGamingImg;
    } else if (logo) {
      image = `${env.mediaUrl}/gravity-media/${logo}`;
    }

    return image;
  }, [logo]);

  return (
    <div className="ticket-wr">
      {showLogo ? (
        <img
          className="provider-logo"
          src={drawLogo}
          alt="Pascal Gaming"
          onLoad={onImageLoad}
        />
      ) : null}

      {!+barcode ? (
        <h1 style={{ marginTop: "4mm", fontSize: "20px" }}>{t(T.COPY)}</h1>
      ) : null}

      <div className="ticket-content">{children}</div>

      {+barcode ? (
        <div className="barcode-wr">
          <img id="barcode" onLoad={onBarcodeLoad} alt="barcode-err" />
          <span>{barcode}</span>
        </div>
      ) : null}
    </div>
  );
};
