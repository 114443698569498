import React from 'react';

interface TableColumnTitleProps {
  value: string;
}

export const TableColumnTitle: React.FC<TableColumnTitleProps> = ({
  value
}) => {

  return (
    <div className="table-inner-item">
      <span className="table-inner-text">{value}</span>
    </div>
  );
};
