import React from "react";

import { useTranslation } from "../../../../hooks";
import { T } from "../../../../constants";
import { DRAW_SECOND_LOGO } from "../../../../constants/host-name";

interface WelcomeProps {}

export const Welcome: React.FC<WelcomeProps> = () => {
  const t = useTranslation();

  return (
    <div className="cashier-login-left-side">
      <div className="login-left-side-container">
        <h2 className="login-left-side-title">{t(T.WELCOME)}</h2>
        <div className="gravity-logo" />
        <p className="gravitate-text">{t(T.GRAVITY_DESC_LOGIN)}</p>
        {DRAW_SECOND_LOGO ? <div className="gravity-sec-logo" /> : null}
      </div>
    </div>
  );
};
