import React, { memo } from "react";
import { useStore } from "../../../../../../../hooks";
import { PrintTypes } from "../../../../../../../enums";
import { BetTicket } from "../../../../../../../models";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

interface TableRowProps {
  onClick: () => void;
  barCode: number;
  status: number;
  ticketId: number;
  paidAmount: number | null;
  children: React.ReactNode;
}

const TableRow: React.FC<TableRowProps> = observer(
  ({ onClick, barCode, children, ticketId }) => {
    const { ticketsStore } = useStore();
    const {
      tickets: {
        data: { items },
      },
    } = ticketsStore;

    const openPrintPopup = (e: any) => {
      e.stopPropagation();
      const ticket: any = items.filter((item) => item.ticketId === ticketId)[0];
      const {
        amount,
        betType,
        date,
        gameId,
        possibleWin,
        status,
        betId,
        barcode,
        winAmount,
        ticketInfos,
      } = ticket;

      const ticketPrintData: BetTicket = {
        date,
        gameId,
        status,
        amount,
        barcode,
        betId,
        betType,
        ticketId,
        winAmount,
        possibleWin,
        ticketInfo: ticketInfos,
        roundId: ticketInfos.length === 1 ? ticketInfos[0]?.roundId : null,
      };

      ticketsStore.setTicketPrintData({
        type: PrintTypes.Bet,
        data: ticketPrintData,
      });
    };

    return (
      <div onClick={onClick} className="main-table-row">
        {children}
        {
          <div
            className="table-inner-item table-print-item"
            onClick={(e) => openPrintPopup(e)}
          >
            <span className="icon-print" />
          </div>
        }
      </div>
    );
  },
);

export default memo(TableRow);
