import { makeObservable, observable } from "mobx";
import { partnerController } from "../api/controllers/partner";
import { RootStore } from "./index";
import { TicketConfig } from "../models/partnerResponse";

export class PartnerStore {
  isPinRequiredForBalanceChange = false;
  isPinRequiredForPayout = false;
  partnerBusiness = {};

  ticketConfig: TicketConfig = {} as TicketConfig;

  constructor(rootStore?: RootStore) {
    makeObservable(this, {
      isPinRequiredForBalanceChange: observable,
      partnerBusiness: observable,
    });
  }

  public async getPartnerBusiness(): Promise<void> {
    const data = await partnerController.getPartnerBusiness();
    this.isPinRequiredForBalanceChange = data?.isPinRequiredForBalanceChange;
    this.isPinRequiredForPayout = data?.requirePinForPayout;
    this.ticketConfig = data?.ticketConfig;
  }
}
