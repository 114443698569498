import React from "react";

import { KeyValuePair, TableColumn } from "../../../../../models";
import { T } from "../../../../../constants/translation-keys";
import { TableColumnsRow } from "./table-columns-row";
import { Loader } from "../../loader";
import TableBody from "./table-body";

interface TableMainContentProps {
  rowKey?: string;
  loading?: boolean;
  rows: Array<KeyValuePair>;
  columns: Array<TableColumn>;
  onRowClick: (row: any, index: number) => void;
}

export const TableMainContent: React.FC<TableMainContentProps> = ({
  rows,
  rowKey,
  columns,
  loading,
  onRowClick,
}) => {
  return (
    <div className="main-structure-table-wrapper">
      <div className="main-table-wrapper">
        {loading && <Loader text={T.PLEASE_WAIT_FOR_LOADER} />}
        <TableColumnsRow columns={columns} />
        <TableBody
          rows={rows}
          rowKey={rowKey}
          columns={columns}
          onRowClick={onRowClick}
        />
      </div>
    </div>
  );
};
